
    .combined-sign-in {
        &--tab-headers {
            margin: 0 auto 24px;
            width: 50%;
            min-width: 360px;
            max-width: 450px;
            margin-bottom: 20px;
            @media (max-width: 768px) {
             min-width: 100%;
                max-width: 100%;
            }
            display: flex;
            justify-content: space-evenly;
        }

        &--signin-tab, &--signup-tab {
            color: black;
            text-decoration: none;
            text-align: center;
            font-size: 22px;
            line-height: 40px;
            @media (max-width: 768px) {
             font-size: 17px;
            }
        }

        &--signin-tab {
            width: 50%;
        }

        &--signup-tab {
            width: 50%;
        }

        &--selected-tab {
            border-bottom: 4px solid #00A1EC;
        }

        &--tab-header {
            margin: 0;
        }
    }