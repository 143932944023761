.combined-sign-in {
    &--signup .combined-sign-in--custom-image-input--container {
        background-color: white;
    }

    &--custom-image-input--container {
        display: flex;
        position: relative;
        align-items: center;
        margin: 30px auto;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        height: 44px;
        border: 1pt solid #919191;
        border-radius: 4px;
    }

    &--custom-image-input--container label {
        color: #c8c8c8;
        position: relative;
        margin: 15px 12px;
        display: flex;
    }

    &--custom-image-input--input {
        position: absolute;
        width: 100%;
        padding-left: 20px;
        height: 44px;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        box-shadow: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        outline: none;
    }

    &--input-field-focus {
        border: 2px solid #3532EE;
    }

    &--input-field-error {
        border: 2px solid #E4212D;
    }

    &--input-field-success {
        border: 2px solid #0F9F4C;
    }

    &--text-field-error-icon {
        position: absolute;
        right: 20px;
        border: none;
    }

    &--text-field-success-icon {
        position: absolute;
        right: 20px;
        border: none;
    }

    &--custom-image-input--img {
        margin: auto 0;
        padding: 0 8px 0 0;
        border-right: 1px solid #c8c8c8;
        height: 16px;
        width: 24px;
    }

    &--custom-image-input--default {
        margin-left: 8px;
    }
}

@media (max-width: 768px) {
    .combined-sign-in {
        &--custom-image-input--container {
            margin: 12px auto;
        }
    }
}