button:hover {
    cursor: pointer;
}

.combined-sign-in {
    &--signup, &--signin {
            width: 50%;
            min-width: 360px;
            max-width: 450px;
            margin-bottom: 20px;
                        height: 468px;

                        @media (max-width: 768px) {
             min-width: 100%;
                max-width: 100%;
            }
    }

    &--errors {
        font-size: 14px;
        line-height: 28px;
        color: #E4212D;
    }

    &--desktop-tab {
        font-size: 28px;
        line-height: 40px;
        font-weight: normal;
        margin: 0px auto 20px;
    }

    &--input-form > div:first-of-type {
        margin-top: 0;
    }

    &--username-and-password > div:last-of-type > div:last-of-type {
        margin: -15px 4px 0;
    }

    &--button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00A1Ec;
        width: 100%;
        height: 48px;
        color: white;
        font-size: 16px;
        font-weight: bold;
        line-height: 32px;
        text-transform: uppercase;
        border: none;
        border-radius: 4px;
    }

    &--disabled-button {
        background-color: #F3F3F3;
        width: 100%;
        height: 48px;
        color: #838BB2;
        font-size: 16px;
        font-weight: bold;
        line-height: 32px;
        text-transform: uppercase;
        border: 1px solid #919191;
        border-radius: 4px;
        display: flex;
            align-items: center;
            justify-content: center;

        &:hover {
            cursor: not-allowed !important;
        }
    }

    &--captcha-container {
        margin: 30px 0;
    }

    &--forgot-password {
        font-size: 16px;
        line-height: 28px;
        width: 100%;
        text-align: center;
        display: inline-block;
        margin: 15px 0 0;
    }

    &--create-account-terms {
        margin-top: 14px;
        font-size: 14px;
        line-height: 28px;
    }

    &--divider {
        margin: 20px auto;
        color: #c8c8c8;
    }

    &--alternate-signin, &--alternate-signup {
        margin: 15px 0;
        font-size: 14px;
        line-height: 28px;

        &--buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            button {
                width: 100%;
            }

            :nth-child(2) {
                margin-left: 9px;
            }
        }

        img {
            height: 17px;
        }
    }

    &--facebook-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48px;
        margin: 10px 0;
        background-color: #325C95;
        color: white;
        font-size: 16px;
        font-weight: bold;
        line-height: 32px;
        text-transform: uppercase;
        border: none;
        border-radius: 4px;
    }

    &--apple-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 335px;
        height: 48px;
        margin: 10px 0;
        background-color: #232323;
        color: white;
        font-size: 16px;
        font-weight: bold;
        line-height: 32px;
        vertical-align: center;
        border: none;
        border-radius: 4px;

        img {
            margin-top: -2px;
        }
    }
}

@media (max-width: 768px) {
    .combined-sign-in {
        &--signup, &--signin {
            min-width: 0;
            width: 100%;
            padding: 0;
        }

        &--signup {
            background-color: white;
        }

        &--username-and-password {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 12px 0;

            > div {
                margin: 0;
                width: 48%;
            }
        }
    }
}
