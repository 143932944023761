@font-face {
    font-family: 'proxima-nova';
    src: url(./assets/fonts/ProximaNovaOTF/ProximaNova-Reg.otf) format('opentype');
}

@font-face {
    font-family: 'proxima-nova-bold';
    src: url(./assets/fonts/ProximaNovaOTF/ProximaNova-Sbold.otf) format('opentype');
}

* {
    box-sizing: border-box;
}

#root {
    font-family: proxima-nova;
}

.hidden {
    display: none;
}

.combined-sign-in {
    &--container {
        margin: auto;
        max-width: 100%;
    }

    &--header1 {
        font-family: proxima-nova-bold;
        margin: 50px 50px 25px;
        text-align: left;
        font-size: 22px;
        line-height: 30px;
        text-transform: uppercase;
    }

    &--header {
        font-family: proxima-nova-bold;
        margin: 50px 50px 20px;
        text-align: left;
        font-size: 22px;
        line-height: 42px;
        text-transform: uppercase;
    }

    &--signin-signup {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

@media (min-width: 768px) {
    .show-on-mobile {
        display: none;
    }
}

.hide-on-mobile {
    display: none;
}

@media (max-width: 768px) {


    .combined-sign-in {
        &--container {
            min-width: 100%;
            max-width: 100%;
        }

        &--header1 {
            margin: 30px 50px 5px;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
        }

        &--header {
            margin: 30px 50px 12px;
            font-size: 20px;
            line-height: 42px;
            text-align: center;
        }

        &--signin-signup {
            margin: 10px;
        }
    }
}

.combined-sign-in--container {
    display: flex;
    height: 100vh;

    .combined-sign-in--image {
        width: 33vw;
        max-width: 100%;
        position: relative;
        overflow: hidden;

        @media (max-width: 768px) {
            display: none;
        }
    }

    .combined-sign-in--form {
        width: 67vw;
        max-width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        text-align: center;

        @media (max-width: 768px) {
            width: 100%;
        }


        .combined-sign-in--signin-signup {
            width: 100%;
            max-width: 100%;
        }
    }
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.combined-sign-in--logo {
    width: 50%;
    min-width: 360px;
    max-width: 450px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        min-width: 100%;
        max-width: 100%;
    }
}

.slider-container {
    position: relative;
    width: 30%;
    height: 100vh;
    overflow: hidden;

}

@media (max-width: 767px) {
    .slider-container {
        position: absolute;
        inset: 0;
        z-index: 1;
        width: 100%;
        display: none;
    }
}

.slider-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 2s ease-in-out;
}

.slider-image.active {
    opacity: 1;
    z-index: 1;
}

.slider-image:not(.active) {
    opacity: 0;
    transition: opacity 2s ease-in-out;
}
.only-mobile,
.only-mobile--block,
.only-mobile--inline-block,
.only-mobile--flex,
.only-tablet,
.only-tablet--block,
.only-tablet--inline-block,
.only-tablet--flex,
.only-desktop,
.only-desktop--block,
.only-desktop--inline-block,
.only-desktop--flex,
.only-wide-desktop,
.only-wide-desktop--block,
.only-wide-desktop--inline-block,
.only-wide-desktop--flex {
  display: none !important;
}
@media (max-width: 767px) {
  .only-mobile {
    display: inline !important;
  }
  .only-mobile--block {
    display: block !important;
  }
  .only-mobile--inline-block {
    display: inline-block !important;
  }
  .only-mobile--flex {
    display: flex !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .only-tablet {
    display: inline !important;
  }
  .only-tablet--block {
    display: block !important;
  }
  .only-tablet--inline-block {
    display: inline-block !important;
  }
  .only-tablet--flex {
    display: flex !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .only-desktop {
    display: inline !important;
  }
  .only-desktop--block {
    display: block !important;
  }
  .only-desktop--inline-block {
    display: inline-block !important;
  }
  .only-desktop--flex {
    display: flex !important;
  }
}
@media (min-width: 1200px) {
  .only-wide-desktop {
    display: inline !important;
  }
  .only-wide-desktop--block {
    display: block !important;
  }
  .only-wide-desktop--inline-block {
    display: inline-block !important;
  }
  .only-wide-desktop--flex {
    display: flex !important;
  }
}
.gte-mobile,
.gte-mobile--flex,
.gte-mobile--block,
.gte-mobile--inline-block,
.gte-tablet,
.gte-tablet--flex,
.gte-tablet--block,
.gte-tablet--inline-block,
.gte-desktop,
.gte-desktop--flex,
.gte-desktop--block,
.gte-desktop--inline-block,
.gte-wide-desktop,
.gte-wide-desktop--flex,
.gte-wide-desktop--block,
.gte-wide-desktop--inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .gte-mobile {
    display: inline !important;
  }
  .gte-mobile--block {
    display: block !important;
  }
  .gte-mobile--inline-block {
    display: inline-block !important;
  }
  .gte-mobile--flex {
    display: flex !important;
  }
}
@media (min-width: 768px) {
  .gte-tablet {
    display: inline !important;
  }
  .gte-tablet--block {
    display: block !important;
  }
  .gte-tablet--inline-block {
    display: inline-block !important;
  }
  .gte-tablet--flex {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .gte-desktop {
    display: inline !important;
  }
  .gte-desktop--block {
    display: block !important;
  }
  .gte-desktop--inline-block {
    display: inline-block !important;
  }
  .gte-desktop--flex {
    display: flex !important;
  }
}
@media (min-width: 1200px) {
  .gte-wide-desktop {
    display: inline !important;
  }
  .gte-wide-desktop--block {
    display: block !important;
  }
  .gte-wide-desktop--inline-block {
    display: inline-block !important;
  }
  .gte-wide-desktop--flex {
    display: flex !important;
  }
}
.lte-mobile,
.lte-mobile--inline-block,
.lte-mobile--block,
.lte-mobile--flex,
.lte-tablet,
.lte-tablet--inline-block,
.lte-tablet--block,
.lte-tablet--flex,
.lte-desktop,
.lte-desktop--inline-block,
.lte-desktop--block,
.lte-desktop--flex {
  display: none !important;
}
@media (max-width: 767px) {
  .lte-mobile {
    display: inline !important;
  }
  .lte-mobile--block {
    display: block !important;
  }
  .lte-mobile--inline-block {
    display: inline-block !important;
  }
  .lte-mobile--flex {
    display: flex !important;
  }
}
@media (max-width: 991px) {
  .lte-tablet {
    display: inline !important;
  }
  .lte-tablet--block {
    display: block !important;
  }
  .lte-tablet--inline-block {
    display: inline-block !important;
  }
  .lte-tablet--flex {
    display: flex !important;
  }
}
@media (max-width: 1199px) {
  .lte-desktop {
    display: inline !important;
  }
  .lte-desktop--block {
    display: block !important;
  }
  .lte-desktop--inline-block {
    display: inline-block !important;
  }
  .lte-desktop--flex {
    display: flex !important;
  }
}
.bb-hide {
  display: none !important;
}
.bb-address {
  font-style: normal;
}
.bb-hr-with-label {
  background-color: #c8c8c8;
  height: 1px;
  text-align: center;
  margin: 30px 0;
}
.bb-hr-with-label__label {
  background-color: #fff;
  padding: 0 10px;
  display: inline-block;
  transform: translateY(-8px);
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  color: #949494;
  text-transform: uppercase;
}
.bb-clearfix:before,
.clearFloatNoHeight:before,
.bb-clearfix:after,
.clearFloatNoHeight:after {
  content: ' ';
  display: table;
}
.bb-clearfix:after,
.clearFloatNoHeight:after {
  clear: both;
}
.bb-circle-image {
  border-radius: 50%;
}
.bb-rounded-corner-image {
  border-radius: 2px;
}
.bb-no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}
.bb-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bb-list--plain {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.bb-link--plain {
  color: inherit;
}
.bb-link--plain:hover {
  text-decoration: none;
}
.bb-underlay,
.bb-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.33);
  z-index: 490;
}
.bb-lock-scroll {
  overflow: hidden;
}
a,
.bb-link {
  color: #428bca;
  cursor: pointer;
  text-decoration: none;
}
a:hover,
.bb-link:hover {
  color: #428bca;
  text-decoration: none;
}
.bb-h1,
.bb-h2,
.bb-h3,
.bb-h4,
.bb-h5,
.bb-h6 {
  font-family: ProximaNovaExCnSemiBold, Arial, sans-serif;
  line-height: 1.1;
  text-transform: uppercase;
  margin: 0 0 15px;
  font-weight: normal;
}
.bb-h1 {
  font-size: 42px;
}
.bb-h2 {
  font-size: 36px;
}
.bb-h3 {
  font-size: 24px;
}
.bb-h4 {
  font-size: 20px;
}
.bb-h5 {
  font-size: 16px;
  font-family: ProximaNovaReg, ProximaNova, Arial, sans-serif;
  font-weight: bold;
}
.bb-h6 {
  font-size: 14px;
  font-family: ProximaNovaReg, ProximaNova, Arial, sans-serif;
}
.Grid-row,
.Grid-row--resp,
.Grid-unit--flex {
  display: flex;
}
.Grid-row--centered {
  justify-content: center;
}
.Grid-row--right {
  justify-content: flex-end;
}
.Grid-row--wrap {
  flex-wrap: wrap;
  margin: 0 -10px;
}
.Grid-spacer {
  flex: 1;
}
.Grid-unit {
  padding-left: 10px;
}
.Grid-row .Grid-unit:first-child {
  padding-left: 0;
}
.Grid-row--wrap .Grid-unit:first-child {
  padding-left: 10px;
}
.Grid-unit--no-gutter {
  padding-left: 0;
}
.Grid-unit--stretch {
  flex: 1;
}
.Grid-row .Grid-unit--with-gutter:first-child {
  padding-left: 10px;
}
.Grid-row--valign-center {
  align-items: center;
}
.Grid-unit--valign-center {
  align-self: center;
}
.Grid-unit--center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Grid-divider {
  flex: 0 0 1px;
  background-color: #c8c8c8;
}
.Grid-row--resp {
  flex-direction: column;
}
.Grid-row--resp .Grid-unit {
  padding-left: 0;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .Grid-row--resp {
    flex-direction: row;
  }
  .Grid-row--resp .Grid-unit {
    padding-left: 10px;
    margin-bottom: 0;
  }
  .Grid-row--resp .Grid-unit:first-child {
    padding-left: 0;
  }
}
.Grid-row--resp {
  display: block;
}
.Grid-row--resp .Grid-unit {
  padding-left: 0;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .Grid-row--resp {
    display: flex;
  }
  .Grid-row--resp .Grid-unit {
    padding-left: 10px;
    margin-bottom: 0;
  }
  .Grid-row--resp .Grid-unit:first-child {
    padding-left: 0;
  }
}
.bb-textarea,
.bb-input,
.bb-select {
  font-size: 16px;
  color: #474747;
  font-family: Helvetica, Arial, Sans-serif;
}
@media (min-width: 768px) {
  .bb-textarea,
  .bb-input,
  .bb-select {
    font-size: 15px;
  }
}
.bb-textarea,
.bb-input {
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 3px;
  border: 1px solid #919191;
  box-sizing: border-box;
  transition: border-color ease-in-out 0.15s;
}
.bb-textarea:focus,
.bb-input:focus {
  border-color: #005a8b;
  outline: none;
}
.bb-input {
  padding: 8px 10px;
  line-height: 1.25;
}
.bb-input--stretch,
.bb-textarea--stretch {
  width: 100%;
}
.bb-input--no-border {
  border: none;
  padding: 10px;
}
.bb-input--no-spinners {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}
.bb-input--no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.bb-label {
  color: #303030;
  font-size: 14px;
}
.bb-textarea {
  line-height: normal;
  padding: 10px;
  background-color: #fff;
  resize: none;
  min-height: 40px;
}
.bb-textarea[msd-elastic] {
  resize: none;
  height: 18px;
}
.bb-textarea--stop-growing {
  max-height: 400px;
}
[contenteditable='true'] {
  min-height: 38px;
}
.bb-inputs-wrap {
  margin: 15px 0;
}
.bb-input-wrap {
  margin-bottom: 15px;
}
.bb-input-hint {
  font-size: 13px;
  color: #ababab;
}
.bb-input-hint--below {
  margin-top: 3px;
}
.placeholder-mixin {
  color: #c8c8c8;
}
.bb-textarea::placeholder {
  color: #c8c8c8;
}
.bb-input::placeholder {
  color: #c8c8c8;
}
[contentEditable='true']:empty:not(:focus):before {
  content: attr(data-placeholder);
  color: #c8c8c8;
}
.bb-input-in-field-wrap {
  position: relative;
}
.bb-input-infield-wrap__fit-it {
  display: inline-block;
  position: relative;
}
.bb-has-infield--top,
.bb-has-infield--bottom,
.bb-has-infield--right {
  box-sizing: content-box;
}
.bb-has-infield--bottom {
  padding-bottom: 19px;
}
.bb-has-infield--top {
  padding-top: 24px;
}
.bb-has-infield--right {
  padding-right: 20px;
}
.bb-label--in-field-bottom,
.bb-label--in-field-top {
  position: absolute;
  left: 10px;
}
.bb-label--in-field-right {
  position: absolute;
  right: 10px;
}
.bb-label--in-field-top {
  color: #ababab;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.bb-label--in-field-bottom,
.bb-label--in-field-right {
  color: #919191;
  font-size: 11px;
}
.bb-label--in-field-bottom {
  bottom: 4px;
}
.bb-label--in-field-right {
  bottom: 10px;
}
.bb-label--in-field-top {
  top: 7px;
}
.bb-select-wrap {
  position: relative;
}
.bb-select-wrap:after {
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 7px solid #999;
  position: absolute;
  top: 50%;
  margin-top: -3.5px;
  right: 10px;
}
.bb-select--wrapped {
  width: 100%;
  padding: 0 25px 0 12px;
  line-height: 40px;
  height: 40px;
  color: #4d4f53;
  border-radius: 2px;
  text-shadow: #fff 0 1px 0;
  box-shadow: 0 2px 1px -1px #fff;
  background-image: linear-gradient(#fff, #ececec);
  border: 1px solid #ccc;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}
.bb-select--wrapped::-ms-expand {
  display: none;
}
@media (min-width: 768px) {
  .bb-select--wrapped {
    font-size: 14px;
  }
}
input.ng-dirty.ng-invalid,
textarea.ng-dirty.ng-invalid,
select.ng-dirty.ng-invalid {
  border-color: #CC0400;
}
input.ng-dirty.ng-invalid:focus,
textarea.ng-dirty.ng-invalid:focus,
select.ng-dirty.ng-invalid:focus {
  outline: none;
}
.bb-input--error,
.bb-textarea--error {
  border-color: #CC0400;
}
.bb-form-msg--error {
  color: #CC0400;
}
.bb-form-msg--info {
  color: #303030;
}
.bb-btn--plain {
  background-color: transparent;
  border: 0;
  padding: 0;
  outline: 0;
  border-radius: 0;
  font-size: inherit;
  font-family: inherit;
}
.bb-btn--plain:focus:not(.preserve-outline) {
  outline: none;
}
.bb-btn-base,
.bb-btn--facebook {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  display: inline-block;
  line-height: normal;
  white-space: nowrap;
  vertical-align: baseline;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-family: ProximaNovaExCnSemiBold, Arial, sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 0.1em 0.5em 0;
}
.bb-btn--stretch {
  width: 100%;
}
.bb-flat-btn,
.bb-flat-btn--primary {
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-weight: normal;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  color: #fff;
  border-radius: 2px;
  font-family: ProximaNovaSemibold, Arial, Sans-serif;
  text-transform: uppercase;
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  background-color: #00aeef;
  border: 1px solid #00aeef;
}
.bb-flat-btn:hover,
.bb-flat-btn--primary:hover {
  color: #fff;
  text-decoration: none;
  background-color: #009ad2;
}
.bb-flat-btn--secondary {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  color: #919191;
}
.bb-flat-btn--secondary:hover {
  color: #919191;
  background-color: #e6e6e6;
}
.bb-flat-btn--tertiary {
  background-color: #fff;
  border: 1px solid #c8c8c8;
  color: #919191;
}
.bb-flat-btn--tertiary:hover {
  color: #919191;
  background-color: #f5f5f5;
}
.bb-flat-btn--quaternary {
  background-color: #fff;
  border: 1px solid #00aeef;
  color: #00aeef;
}
.bb-flat-btn--quaternary:hover {
  color: #fff;
  background-color: #00aeef;
  border-color: #00aeef;
}
.bb-flat-btn--quinary {
  background-color: transparent;
  border: 1px solid #919191;
  color: #fff;
}
.bb-flat-btn--quinary:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: #919191;
}
.bb-flat-btn--transparent {
  background-color: transparent;
  border: 1px solid transparent;
  color: #303030;
}
.bb-flat-btn--transparent:hover {
  color: #303030;
  background-color: #f9f9f9;
  border-color: #c8c8c8;
}
.bb-flat-btn--error {
  background-color: #cc0400;
  border: 1px solid #cc0400;
  color: #fff;
}
.bb-flat-btn--error:hover {
  color: #fff;
  background-color: #e60500;
}
.bb-flat-btn[disabled]:hover {
  background-color: #00aeef;
  cursor: not-allowed;
}
.bb-flat-btn--secondary[disabled]:hover {
  background-color: #f4f4f4;
  cursor: not-allowed;
}
.bb-flat-btn--secondary[disabled]:hover {
  background-color: #fff;
  cursor: not-allowed;
}
.bb-flat-btn--disabled[disabled] {
  background-color: #919191;
  border: 1px solid #919191;
  color: #fff;
}
.bb-flat-btn--disabled[disabled]:hover {
  background-color: #919191;
  cursor: not-allowed;
}
.bb-flat-btn--size-xl {
  height: 70px;
  line-height: 70px;
  padding: 0 40px;
  font-size: 20px;
}
.bb-flat-btn--size-lg {
  height: 54px;
  line-height: 54px;
  padding: 0 30px;
  font-size: 16px;
}
.bb-flat-btn--size-md {
  height: 42px;
  line-height: 42px;
  padding: 0 30px;
  font-size: 16px;
}
.bb-flat-btn--size-sm {
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  font-size: 12px;
}
.bb-flat-btn--stretch {
  width: 100%;
}
.bb-flat-btn--titlecase {
  text-transform: none;
}
.bb-btn--facebook {
  background-color: #3664a2;
  border: 1px solid #3664a2;
  font-family: ProximaNovaReg, ProximaNova, Arial, sans-serif;
  color: #fff;
  text-transform: none;
  font-size: 16px;
  padding: 0.6em 1em 0.5em;
}
.bb-btn--facebook:before {
  content: '';
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAQAAAC0NkA6AAAAw0lEQVR4Ae3TIU7EUByE8XGEpBWtwKM26dq9xF5iNzgE90ATLvFSBBWcBodD1r2UFD70C4qk80jIf8bPT43oSMy4MpPoRMKdJLIdyaJCAvkjZPEhn7xww0CLEA1XDBy3RSb26Ge3Q1buEPIit8iNPCE3snDtR56RHzkXk5c88r79GXcF8uB5fFsgbx5ERT9qIPxfJBD9og1ffuQAfuRUA7mvgUw1kFc/csEaZ/QggQQSSCCBZDuSRbIjSfSMZBuSGem/AeTDs7iV5E4RAAAAAElFTkSuQmCC);
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: contain;
  margin-right: 10px;
  vertical-align: text-bottom;
  transform: translateY(-1px);
}
@keyframes three-quarters-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.bb-spinner-btn {
  position: relative;
}
.bb-spinner-btn__spinner {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  animation: three-quarters-loader 1250ms infinite linear;
  border: 2px solid white;
  border-right-color: transparent;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  text-indent: -9999px;
  direction: ltr;
}
.bb-spinner-btn__spinner--inverse {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  animation: three-quarters-loader 1250ms infinite linear;
  border: 2px solid #474747;
  border-right-color: transparent;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  text-indent: -9999px;
  direction: ltr;
}
/* Include this file in your html if you are using the CSP mode. */

@charset "UTF-8";

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak],
.ng-cloak, .x-ng-cloak,
.ng-hide:not(.ng-hide-animate) {
  display: none !important;
}

ng\:form {
  display: block;
}

/* stylelint-disable length-zero-no-unit */
/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}


/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/

/* stylelint-enable */
.Mxn-reset,
.Wr__logo,
.Wr__footer__list,
.Wr__footer__image-list,
.Wr-Payments,
.Wr-SiteLinks {
  margin: 0;
  padding: 0;
}
@media (min-width: 992px) {
  .Wr__box,
  .Wr__header__nav,
  .Wr__header-bar__row {
    margin: 0 auto;
    padding: 0;
    width: 970px;
  }
  .Wr__box--stretch {
    width: auto;
  }
  .Wr__header__nav {
    position: relative;
  }
}
@media (min-width: 1200px) {
  .Wr__box,
  .Wr__header__nav,
  .Wr__header-bar__row {
    width: 1170px;
  }
  .Wr__box--stretch {
    width: auto;
  }
}
.Wr__box,
.Wr__header__nav,
.Wr__header-bar__row {
  clear: both;
}
.Wr__html,
.Wr__body {
  background-color: #000;
}
@media (min-width: 768px) {
  .is-ie11 .Wr__body {
    overflow-x: hidden;
  }
}
.header__fullspan--top + .Wr__content {
  padding-top: 30px;
}
.Wr__header {
  width: 100% !important;
  z-index: 301;
  background-color: #303030;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .Wr__header {
    position: sticky;
    top: 0;
  }
}
.Wr__logo {
  text-indent: -22222px;
  direction: ltr;
  position: absolute;
  left: 50%;
  margin-left: -19px;
  background-color: #33b1f0;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  min-width: 57px;
}
@media (min-width: 992px) {
  .Wr__logo {
    position: static;
    left: auto;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .Wr__logo {
    width: 75px;
    min-width: 75px;
    height: 75px;
  }
}
@media (max-width: 991px) {
  .Wr__logo {
    width: 57px;
    height: 57px;
  }
}
@media (max-width: 767px) {
  .Wr__logo {
    order: 2;
  }
}
.Wr__header__nav {
  background-color: #303030;
  color: #fff;
  box-sizing: border-box;
  padding: 10px 15px;
  height: 57px;
  justify-content: center;
}
.Wr__header__site-nav {
  margin-left: 20px;
  margin-right: 20px;
  flex-grow: 1;
}
.Wr__header__site-nav__menu {
  width: 100%;
}
.Wr__nav-menu {
  display: flex;
}
.Wr__nav-menu__unit {
  text-transform: uppercase;
  font-family: ProximaNovaSemibold, Arial, sans-serif;
  font-size: 12px;
  text-align: center;
}
.Wr__nav-menu__unit--active {
  color: #00aeef;
}
.Wr__user-menus {
  display: flex;
  align-items: center;
  margin-left: 14px;
}
.Wr__header__nav__icons {
  align-items: center;
  line-height: 1;
}
.Wr__header__nav__icons .Icon-profile,
.Wr__header__nav__icons .Icon-cart {
  vertical-align: top;
}
.Wr__user-menus__menu {
  padding-left: 0;
}
.Wr__user-menus__cart {
  margin-left: 14px;
}
@media (min-width: 992px) {
  .Wr__header__nav {
    display: flex;
    padding: 0;
  }
  .Wr__header__site-nav {
    margin-left: 10px;
    margin-right: 10px;
  }
  .Wr__header__site-nav.typeahead-open {
    display: none !important;
  }
  .Wr__header__nav__unit {
    display: flex;
    align-items: center;
  }
  .Wr__header__nav__unit,
  .Wr__nav-menu,
  .Wr__nav-menu__unit {
    height: 100%;
  }
  .Wr__nav-menu__unit {
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.15s ease-in;
  }
  .Wr__nav-menu__unit:hover {
    background-color: #fff;
  }
  .Wr__nav-menu__unit:hover,
  .Wr__nav-menu__unit--active {
    color: #00aeef;
  }
}
@media (min-width: 992px) {
  .Wr__header__nav {
    height: 75px;
  }
  .Wr__nav-menu__unit {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .Wr__header__nav {
    display: flex;
    flex-flow: row wrap;
    padding: 0;
    height: auto;
  }
  .Wr__header__site-nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1 0 100px;
    order: 1;
    margin-right: 0;
    margin-left: 0;
    padding-left: 20px;
  }
  .Wr__header__site-nav__menu {
    width: auto;
  }
  .Wr__logo {
    order: 2;
    position: static;
    left: 0;
    margin-left: 0;
    flex: 0;
  }
  .Wr__search {
    flex: 1;
    display: block;
    flex-basis: 100%;
    order: 4;
  }
  .Wr__user-menus {
    order: 3;
    flex: 1 0 100px;
    justify-content: flex-end;
    padding-right: 14px;
    margin-left: 0;
    align-items: center;
    height: auto;
  }
}
.Wr__user-menus__menu {
  height: 57px;
}
@media (min-width: 992px) {
  .Wr__user-menus__menu {
    height: 75px;
  }
}
.Wr__user-menus__menu {
  display: flex;
  align-items: center;
  height: 100%;
}
.Wr__header-bar {
  background-color: #fff;
  box-sizing: border-box;
  color: #474747;
  padding: 0 10px;
  font-size: 13px;
  border-bottom: 1px solid #c8c8c8;
  z-index: 300;
  position: sticky;
  top: 0;
}
@media (min-width: 992px) {
  .Wr__header-bar {
    padding: 0;
    top: 75px;
  }
}
.Wr__header-bar__row {
  display: flex;
  align-items: center;
  height: 30px;
}
.Wr__header-bar__unit {
  display: flex;
  align-items: center;
}
@media (min-width: 992px) {
  .Wr__header-bar__unit {
    flex-basis: 33.333333%;
  }
}
.Wr__CTA,
.Wr__header-bar__link {
  color: #00aeef;
}
.Wr__header-bar__link:hover {
  text-decoration: none;
}

.Wr__CTA {
  justify-content: center;
}
.Wr__header-bar__country-trigger {
  justify-content: flex-end;
}
.Wr__header-bar__country-caret,
.Wr__header-bar__country-icon {
  display: inline-block;
}
.Wr__header-bar__country-label {
  margin-left: 7px;
  vertical-align: middle;
}
.Wr__header-bar__country-modal-link {
  color: #474747;
}
.Wr__header-bar__country-modal-link .Icon-caret-gray {
  display: inline-block;
}
.Wr__header-bar__country-modal-link .Icon-caret-blue {
  display: none;
}
.Wr__header-bar__country-modal-link:hover .Icon-caret-gray {
  display: none;
}
.Wr__header-bar__country-modal-link:hover .Icon-caret-blue {
  display: inline-block;
}
.Wr__header-bar__unit--customerService,
.Wr__header-bar__country-modal-link {
  white-space: nowrap;
}
.Wr__header-bar__row--belite {
  justify-content: center;
}
@media (min-width: 992px) {
  .Wr__header-bar__row--belite {
    justify-content: space-between;
  }
}
.Wr__content {
  background-color: #f2f2f2;
}
.Wr__content__main {
  display: flex;
  flex-direction: column;
}
.MainCol__container {
  min-width: 1px;
}
.Wr__content__content {
  padding: 15px;
  background-color: #fff;
  box-sizing: border-box;
  overflow: auto;
  word-wrap: break-word;
}
.Wr__content__content img {
  max-width: 100%;
}
.Wr__content__nav,
.Wr__content__promo {
  box-sizing: content-box;
  display: none;
  flex: 0 0 167px;
}
@media (min-width: 768px) {
  .Wr__content {
    padding: 20px 30px;
    box-shadow: -700px 0 #f2f2f2, 700px 0 #f2f2f2;
    background-color: #f2f2f2;
  }
  .is-ie11 .Wr__content {
    box-shadow: none;
    position: relative;
  }
  .is-ie11 .Wr__content::before,
  .is-ie11 .Wr__content::after {
    content: '';
    background-color: #f2f2f2;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 6666px;
  }
  .is-ie11 .Wr__content::before {
    right: 100%;
  }
  .is-ie11 .Wr__content::after {
    left: 100%;
  }
  .Wr__content__main {
    flex-direction: row;
  }
  .MainCol__container {
    flex: 1;
  }
  .Wr__content__content {
    padding: 30px;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
  }
  .Wr__content__nav {
    display: block;
    background-color: #f2f2f2;
    padding-right: 30px;
  }
  .Wr__content__promo {
    padding-left: 30px;
  }
}
@media (min-width: 992px) {
  .Wr__content {
    padding-left: 0;
    padding-right: 0;
  }
  .Wr__content__promo {
    display: block;
  }
}
.Wr__footer__body-text {
  color: #838383;
  line-height: 1.4;
  font-size: 12px;
}
.Wr__footer__link,
.bcText a {
  color: #838383;
  text-decoration: none;
  font: 14px/1.42857143 ProximaNovaReg, ProximaNova, Arial, sans-serif;
}
.Wr__footer__link:hover,
.bcText a:hover {
  color: #33b1ef;
  text-decoration: none;
}
.Wr__footer__section1 {
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  background-color: #fff;
}
@media (min-width: 768px) {
  .Wr__footer__section1 {
    box-shadow: -700px 0 #fff, 700px 0 #fff;
    background-color: #fff;
  }
  .is-ie11 .Wr__footer__section1 {
    box-shadow: none;
    position: relative;
  }
  .is-ie11 .Wr__footer__section1::before,
  .is-ie11 .Wr__footer__section1::after {
    content: '';
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 6666px;
  }
  .is-ie11 .Wr__footer__section1::before {
    right: 100%;
  }
  .is-ie11 .Wr__footer__section1::after {
    left: 100%;
  }
}
@media (max-width: 767px) {
  .Wr__footer__section1__col--newsletter {
    order: -1;
  }
}
@media (min-width: 768px) {
  .Wr__footer__section1 {
    justify-content: space-between;
  }
}
@media (max-width: 991px) {
  .Wr__footer__section1__col {
    padding: 0 25px;
  }
  .Wr__footer__section1 {
    flex-wrap: wrap;
  }
  .Wr__footer__section1__col {
    order: 1;
  }
  .Wr__footer__section1__col--newsletter {
    order: 0;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .Wr__footer__section1__col--help {
    padding-right: 0;
    box-sizing: border-box;
    width: 50%;
  }
  .Wr__footer__section1__col--orders-shipping {
    padding-left: 0;
    box-sizing: border-box;
    width: 50%;
  }
  .Wr__footer__section1__col--newsletter {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .Wr__footer__hr {
    margin-top: 28px;
    height: 1px;
    background-color: #383838;
  }
}
.Wr__footer__heading {
  color: #303030;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1;
  font-weight: normal;
  margin: 30px 0 10px;
}
@media (max-width: 767px) {
  .Wr__footer__heading {
    margin: 30px 0 15px;
  }
}
@media (min-width: 768px) {
  .Wr__footer__heading {
    margin: 40px 0 5px;
  }
}
@media (min-width: 992px) {
  .Wr__footer__heading {
    height: 20px;
  }
}
.Wr__footer__image-list {
  display: flex;
  margin-top: 8px;
}
.Wr__footer__list,
.Wr__footer__image-list {
  list-style-type: none;
}
.Wr__footer__list__item {
  display: block;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .Wr__footer__list__item {
    margin-bottom: 2px;
  }
}
.Wr__footer__image-list__item {
  display: inline-block;
  margin-right: 8px;
}
.Wr__confidence-logo--instagramLink {
  margin-right: 0;
}
.Wr__footer__newsletter-form {
  text-align: center;
  margin-top: 10px;
}
.Wr__footer__newsletter-text {
  max-width: 305px;
  font-size: 14px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .Wr__footer__newsletter__text-group {
    margin-right: 55px;
  }
  .Wr__footer__newsletter-form {
    flex: 1;
  }
}
@media (min-width: 768px) {
  .Wr__footer__newsletter-form {
    min-height: 82px;
  }
}
@media (min-width: 992px) {
  .Wr__footer__newsletter-form {
    margin-top: 15px;
    text-align: left;
    min-height: 0;
  }
}
.Wr-Footer2 {
  text-align: center;
  padding: 20px 10px;
  background-color: #f9f9f9;
}
@media (min-width: 768px) {
  .Wr-Footer2 {
    box-shadow: -700px 0 #f9f9f9, 700px 0 #f9f9f9;
    background-color: #f9f9f9;
    margin-top: -1px;
  }
  .is-ie11 .Wr-Footer2 {
    box-shadow: none;
    position: relative;
  }
  .is-ie11 .Wr-Footer2::before,
  .is-ie11 .Wr-Footer2::after {
    content: '';
    background-color: #f9f9f9;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 6666px;
  }
  .is-ie11 .Wr-Footer2::before {
    right: 100%;
  }
  .is-ie11 .Wr-Footer2::after {
    left: 100%;
  }
}
.Wr-Payments,
.Wr-SiteLinksGroup,
.Wr-Copyright,
.Wr-Address,
.Wr-Disclaimer {
  margin-bottom: 20px;
}
.Wr-Disclaimer {
  margin-left: auto;
  margin-right: auto;
  max-width: 580px;
}
.Wr-Payments {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  margin-top: -6px;
}
.Wr-Payments__item {
  margin: 6px 6px 0;
}
.Wr-SiteLinksGroup {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .Wr-SiteLinksGroup {
    flex-direction: row;
    justify-content: center;
  }
}
.Wr-SiteLinks {
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 10px;
}
.Wr-SiteLinks__item {
  padding-right: 7px;
  margin-right: 7px;
  border-right: 1px solid #838383;
  line-height: 10px;
}
@media (min-width: 321px) {
  .Wr-SiteLinks__item {
    padding-right: 10px;
    margin-right: 10px;
  }
}
.Wr-SiteLinks__item--last {
  border-right: none;
  padding-right: 0;
  margin-right: 0;
}
@media (min-width: 768px) {
  .Wr-SiteLinks__item--first-last {
    border-right: 1px solid #838383;
    padding-right: 10px;
    margin-right: 10px;
  }
}
.Wr-Address__phone-link,
.Wr-SiteLinks__link {
  font-size: 12px;
  color: #838383;
  cursor: pointer;
}
.Wr-Address__phone-link:hover,
.Wr-SiteLinks__link:hover {
  color: #00aeef;
  text-decoration: none;
}
@media (min-width: 321px) {
  .Wr-Address__phone-link,
  .Wr-SiteLinks__link {
    font-size: 14px;
  }
}
@media (min-width: 376px) {
  .Wr-Address__phone-link,
  .Wr-SiteLinks__link {
    font-size: 15px;
  }
}
.Wr-Address__phone-link {
  font-size: inherit;
}
@media (min-width: 768px) {
  .Wr-Address__phone-link:before {
    content: '- ';
  }
}
/**
* package
*
* Created by btilford on 11/13/14.
*/
.Login__fb-error-msg {
  margin: 10px 0;
}
.Login {
  max-width: 380px;
  margin: 0 auto;
}
.Login__well {
  margin: 15px 0;
  text-align: center;
  color: #303030;
}
.Login__signup {
  text-align: center;
}
.Login__signup-link {
  display: inline-block;
  padding-left: 2px;
}
.Login__heading {
  font-size: 18px;
  line-height: 1.4;
  text-align: center;
  margin: 0 0 12px;
}
.is-framed .Login {
  margin-bottom: 100px;
}
.frame-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 75px;
  border-top: 1px solid #c8c8c8;
  padding: 15px;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.FlashMessage {
  background-color: #62bd19;
  color: white;
  font-weight: bold;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition-property: 'opacity';
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  font-size: 13px;
  height: 60px;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 1000;
}
.FlashMessage-opened {
  opacity: 1;
  overflow-y: visible;
  max-height: 60px;
}
@media (min-width: 375px) {
  .FlashMessage {
    font-size: 14px;
  }
}
@media (min-width: 414px) {
  .FlashMessage {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .FlashMessage {
    font-size: 18px;
  }
}
/* stylelint-disable length-zero-no-unit */
/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.Icon-checkmark {
  background-position: 0px 0px;
  width: 14px;
  height: 15px;
}
/* stylelint-enable */
html,
body {
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}
.UserActContent {
  background-color: #fff;
  padding: 40px 20px 80px;
}
.UserActContent--aboutMe {
  padding: 0;
}
.Signup,
.Login,
.UserAct {
  margin: 0 auto;
}
.UserAct {
  max-width: 380px;
  color: #474747;
}
.UserAct-h2 {
  font-size: 21px;
  line-height: 1.4;
  text-align: center;
  margin: 0 0 2px;
}
.UserAct-p {
  line-height: 1.4;
  text-align: center;
  color: #919191;
  font-size: 14px;
  margin: 0 0 30px;
}
.UserAct-submitMsg {
  margin-top: 15px;
}
.UserAct-submitMsg__success {
  color: #474747;
}
.UserAct-submitMsg__failure {
  color: #cc0400;
}
.UserAct {
  max-width: 380px;
}
.UserAct-input {
  width: 90%;
  width: calc(100% - 22px);
  padding-bottom: 7px;
}
.UserAct-validationMsg {
  margin-top: 3px;
  margin-bottom: 20px;
  font-size: 14px;
}
.UserAct-btn {
  font-size: 14px;
}
.UserAct-isValid {
  color: #62bd19;
}
.UserAct-checkmark {
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 5px;
  background-position: 0px 0px;
  width: 14px;
  height: 15px;
}
.UserAct-loader {
  position: relative;
  min-height: 200px;
}
.UserAct-loaderSpinner {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -10px;
  animation: three-quarters-loader 1250ms infinite linear;
  border: 2px solid #00aeef;
  border-right-color: transparent;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  text-indent: -9999px;
  direction: ltr;
}
.UserAct .bb-flat-btn:disabled {
  background-color: #919191;
  border: 1px solid #919191;
  color: #fff;
}
.UserAct-recaptcha {
  margin: 0 -20px 8px;
}
.UserAct-recaptcha > div {
  margin: 0 auto;
}
.UserAct-emailLink {
  display: block;
  margin: 10px 0;
}
/* stylelint-disable length-zero-no-unit */
/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.Icon-gender {
  background-position: 0px -27px;
  width: 18px;
  height: 30px;
}
.Icon-unit-of-measure {
  background-position: 0px 0px;
  width: 29px;
  height: 22px;
}
/* stylelint-enable */
.Signup {
  max-width: 500px;
  margin: 0 auto;
  color: #474747;
  font-size: 16px;
}
.Signup-heading-wrap {
  margin: 0 0 50px;
}
.Signup-subheading {
  margin-top: 0;
  text-align: center;
}
.Signup-option-heading {
  text-align: center;
  margin-bottom: 30px;
}
.Signup-option-heading--facebook {
  max-width: 340px;
  margin-left: auto;
  margin-right: auto;
}
.Signup-never-post-to-the-facebook {
  font-size: 13px;
  margin-top: 4px;
  color: #919191;
}
.Signup-or {
  background-color: #fff;
}
.Signup-row {
  margin-bottom: 15px;
}
.Signup-row--extra {
  margin-top: 25px;
}
.Signup-row--extraExtra {
  margin-top: 40px;
}
.Signup-radios-wrap {
  white-space: nowrap;
}
.Signup-radios-label {
  display: inline-block;
  text-transform: uppercase;
  color: #919191;
  font-size: 16px;
  margin-right: 8px;
  padding-left: 23px;
  position: relative;
}
.Signup-radios-label--measure:before,
.Signup-radios-label--gender:before {
  display: block;
  content: '';
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transform-style: preserve-3d;
}
.Signup-radios-label--measure {
  padding-left: 34px;
}
/* stylelint-disable length-zero-no-unit */
.Signup-radios-label--gender:before {
  background-position: 0px -27px;
  width: 18px;
  height: 30px;
}
.Signup-radios-label--measure:before {
  background-position: 0px 0px;
  width: 29px;
  height: 22px;
}
/* stylelint-enable */
.Signup-label {
  color: #474747;
  font-size: 15px;
  font-family: ProximaNova, Arial, Sans-serif;
  margin-right: 6px;
}
.Signup-facebook-data-provided {
  background-color: #62bd19;
  color: #fff;
  padding: 10px;
  border-radius: 2px;
  margin: 20px 0;
}
.Signup-form .bb-label--placeholder {
  display: block;
  margin-bottom: 5px;
}
.Signup-form .bb-flat-btn:disabled {
  background-color: #919191;
  border: 1px solid #919191;
  color: #fff;
}
.Submit-legalese {
  font-size: 12px;
  color: #c8c8c8;
}
.Submit-legalese-link {
  color: #00aeef;
}
.Submit-alreadyHaveAccount {
  font-size: 15px;
}
.is-framed .Signup {
  margin-bottom: 100px;
}
.frame-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 75px;
  border-top: 1px solid #c8c8c8;
  padding: 15px;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.digital-goods {
  max-width: 500px;
  margin: 0 auto;
  color: #474747;
  font-size: 16px;
}
.digital-goods__status {
  text-align: center;
  padding: 20px;
  margin-bottom: 40px;
}
.digital-goods__status--error {
  color: #d8000c;
  background-color: #ffbaba;
}
.digital-goods__status--success {
  color: #4f8a10;
  background-color: #dff2bf;
}
.digital-goods__status--undefined {
  color: #00529b;
  background-color: #bde5f8;
}
.digital-goods__status a {
  text-decoration: underline;
}
.digital-goods__heading {
  margin: 40px 0 20px;
  line-height: 1.1em;
}
.digital-goods__list {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.digital-goods__list--status {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}
.digital-goods__loading {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -10px;
  animation: three-quarters-loader 1250ms infinite linear;
  border: 2px solid #000;
  border-right-color: transparent;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  text-indent: -9999px;
  direction: ltr;
}
.digital-goods__item-container {
  margin-bottom: 10px;
  width: 100%;
}
.digital-goods__item-container:last-child {
  margin-bottom: 0;
}
.digital-goods__item {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 14px 20px;
  box-sizing: border-box;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.digital-goods__item:hover {
  background-color: rgba(0, 174, 239, 0.1);
}
.digital-goods__item--name {
  font-size: 20px;
  line-height: 1.1em;
  margin-bottom: 3px;
}
.digital-goods__item--sku {
  font-size: 15px;
  line-height: 1.2em;
  color: #000;
}
@media (max-width: 400px) {
  .digital-goods__item--name {
    font-size: 18px;
  }
  .digital-goods__item--sku {
    font-size: 14px;
  }
}
.UserAct {
    max-width: 380px;
}

// 1. Tell Less to piss off https://stackoverflow.com/a/17904128
//    while not using strictMath (which fucks up some things in bb-ui)
//    Also, 22px is 20px for left/right border plus 2px for border
.UserAct-input {
    width: 90%;
    width: 'calc(100% - 22px)'; // 1
    padding-bottom: 7px;
}

.UserAct-validationMsg {
    margin-top: 3px;
    margin-bottom: 20px;
    font-size: 14px;
}

.UserAct-btn {
    font-size: 14px;
}

.UserAct-isValid {
    color: #62bd19;
}

.UserAct-checkmark {
    display: inline-block;
    position: relative;
    top: 2px;
    margin-right: 5px;
}
.activate-message, .password-change-form {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 70%;
}
@media (max-width: 991px) {
.activate-message,
    .password-change-form {
                width: 90%;
                    margin: auto;
    }
}
.input-box{

        width: 100%;
            padding-left: 20px;
            height: 44px;
            background-color: rgba(0, 0, 0, 0);
            border: none;
            box-shadow: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            outline: none;
}
.input-group{
    display: flex;
        position: relative;
        align-items: center;
        margin: 30px auto;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        height: 44px;
        border: 1pt solid #919191;
        border-radius: 4px;
}
.form-di {
    display: flex;
    flex-direction: column;
}